import Vue from 'vue'
import axios from 'axios'
import store from '@/store'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const axiosIns = axios.create({
})

axiosIns.interceptors.response.use(response => response, error => {
  let mensajeError = ''
  if (error.response) {
    if (error.response.status === 500) {
      mensajeError = error.response.statusText
    } else if (error.response.status === 404) {
      mensajeError = error.response.data.message
    } else if (error.response.status === 401) {
      mensajeError = error.response.data.message
    }
  } else if (error.request) {
    mensajeError = error.request
  } else if (error.message === 'Network Error') {
    mensajeError = 'Error de conexión con el servidor.'
  } else if (error.code === 'ECONNABORTED') {
    // eslint-disable-next-line
          const tiempo = `${parseInt(store.state.app.timeout / 60)}`;
    mensajeError = `Se supero el tiempo de espara máximo (${tiempo} min.)`
  }
  mensajeError = mensajeError.toLowerCase()
  mensajeError = mensajeError.charAt(0).toUpperCase() + mensajeError.slice(1)

  Vue.$toast(
    {
      component: ToastificationContent,
      props: {
        title: 'Notificación',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
        text: mensajeError,
      },
    },
    {
      position: 'bottom-center',
      timeout: 4000,
    },
  )

  console.log(error.response)
  store.commit('app/UPDATE_ISLOADING', false)

  if (error.response) {
    if (error.response.status === 401) {
      localStorage.removeItem('mainCampana')
      localStorage.removeItem('userData')
      router.push({
        name: 'login',
      }, () => { })
    }
  }

  return Promise.reject(error)
})

Vue.prototype.$http = axiosIns
export default axiosIns
